
import { defineComponent, ref, onMounted } from "vue";
import { loadStripe } from "@stripe/stripe-js/pure";
import Stripe from "@stripe/stripe-js";
import { apiLoadInvoiceStripe } from "@/api/invoices";
import { STRIPY_KEY } from "@/common/config";

export default defineComponent({
  name: "Checkout",
  components: {},
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const isLoading = ref(true);
    let stripe: Stripe.Stripe | null = null;

    const goToCheckout = async (sessionId: string) => {
      if (stripe) {
        await stripe.redirectToCheckout({
          sessionId,
        });
      }
    };

    const loadInvoice = async () => {
      const { data } = await apiLoadInvoiceStripe(props.id);
      await goToCheckout(data.sessionId);
    };

    const load = async () => {
      if (typeof STRIPY_KEY !== "undefined") {
        stripe = await loadStripe(STRIPY_KEY);
        await loadInvoice();
        isLoading.value = false;
      }
    };

    onMounted(load);

    return { isLoading };
  },
});
