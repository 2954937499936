import ApiService from "@/api/api.service";
import { AxiosPromise } from "axios";
import { Invoice } from "@/types/Invoice";
import { PaginationResponse } from "@/types/PaginationResponse";

type InvoicesCreateUseCreditsParams = {
  name: string;
  country: string;
  amount: number;
  vatNumber: string;
};

type InvoicesCreateUseCreditsParamsResponse = {
  sessionId: string;
};

type VATNumberValidateResponse = {
  valid: boolean;
};

type VATValueBYCountryResponse = {
  rate: number;
};

export const apiCreateInvoiceUseCredits = (
  params: InvoicesCreateUseCreditsParams
): AxiosPromise<InvoicesCreateUseCreditsParamsResponse> => {
  return ApiService.post("api/billing/invoices", params);
};

export const apiLoadInvoice = (id: number): AxiosPromise<Invoice> => {
  return ApiService.get(`api/billing/invoices/${id}`);
};

export const apiLoadInvoiceStripe = (
  id: number
): AxiosPromise<InvoicesCreateUseCreditsParamsResponse> => {
  return ApiService.get(`api/billing/invoices/stripe/checkout/${id}`);
};

export const apiValidateVATNumber = (
  vatNumber: string
): AxiosPromise<VATNumberValidateResponse> => {
  return ApiService.get(`/api/billing/invoices/vat/number/${vatNumber}`);
};

export const apiGetVATValueBYCountryCode = (
  code: string
): AxiosPromise<VATValueBYCountryResponse> => {
  return ApiService.get(`/api/billing/invoices/vat/country/${code}`);
};

export const apiUpdateInvoiceBilledTo = (id: number, billedTo: string): AxiosPromise<Invoice> => {
  return ApiService.put(`api/billing/invoices/${id}`, {
    billedTo,
  });
};

export const apiAllInvoices = (page: number): AxiosPromise<PaginationResponse<Invoice[]>> => {
  return ApiService.get(`api/billing/invoices?page=${page}`);
};
