import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_a_spin = _resolveComponent("a-spin")

  return (_openBlock(), _createBlock("div", null, [
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_a_spin, { key: 0 }))
      : _createCommentVNode("", true)
  ]))
}